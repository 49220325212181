body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.react-responsive-modal-modal {
  min-width: 360px !important;
}

.or-text {
  display: flex;
  flex-direction: row;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.1em;
  color: #000000;
}

.or-text:before,
.or-text:after {
  content: "";
  flex: 1 1;
  border-bottom: 1px solid #c4c4c4;
  margin: auto;
}

.eye-icon {
  position: absolute;
  top: 6px;
  right: 9px;
}

button:focus {
  outline: none !important;
}

.phone-error-msg {
  color: #d32f2f;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.66;
  letter-spacing: 0.03333em;
  text-align: left;
  margin-top: 3px;
  margin-left: 14px;
  margin-bottom: 0;
}

.custom-menu-div {
  position: absolute;
  background: white;
  color: black;
  border-radius: 5px;
  box-shadow: 0px 2px 1px -1px rgb(0, 0, 0, 0.2),
    0px 1px 1px 0px rgb(0, 0, 0, 0.14), 0px 1px 3px 0px rgb(0, 0, 0, 0.12);
  padding: 5px 3px;
  right: 10px;
  top: 54px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s, color 5000s ease-in-out 0s;
  transition-delay: background-color 5000s, color 5000s;
}

.MuiTablePagination-toolbar {
  align-items: baseline;
}

.w-99 {
  width: 99% !important;
}

#style-1::-webkit-scrollbar-track {
  /* // -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); */
  border-radius: 10px;
  background-color: #f5f5f5;
}

#style-1::-webkit-scrollbar {
  width: 3px;
  background-color: #f5f5f5;
  height: 5px;
}

#style-1::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #555;
}

.grecaptcha-badge { 
  visibility: hidden;
}

.remove-flag-class {
  background-image: none !important;
}