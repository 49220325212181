.page-title {
  font-size: 20px;
  font-weight: 700;
}

.custom-help-text {
  font-size: 16px;
  font-weight: 400;
}

.custom-tab-sub-header {
  font-size: 16px;
  font-weight: 600;
}

.url-text {
  padding: 8px;
  background: #d5daed;
  border-radius: 7px;
  width: 300px;
  padding-left: 15px;
  font-size: 15px;
  font-weight: 500;
  margin-right: 15px;
  overflow: auto;
}

.url-input {
  width: 300px;
  margin-right: 15px;
  font-size: 15px;
  font-weight: 500;
}

.url-div {
  display: flex;
  align-items: center;
}

.table-copy-text {
    background: #d5daed;
    border-radius: 5px;
    padding: 8px;
    font-weight: 600;
    cursor: pointer;
}
