.country-input-div {
  display: flex;
  align-items: baseline;
  width: 82%;
  padding-left: 14px;
}

.tax-id-div {
  display: flex;
  width: 50%;
  justify-content: space-between;
  padding-right: 4px;
}

.tax-id-input {
  width: 78%;
}

.mobile-input-div {
  display: flex;
  width: 46%;
  padding-left: 15px;

  .code-input {
    width: 25%;
    margin-right: 14px;
  }

  .number-input {
    width: 62%;
  }
}

.website-input-div {
  width: 42%;
  padding-left: 14px;
}

.profile-img {
  width: 50px;
  border-radius: 51px;
}

.second-div {
  border-left: 1px solid #d3d3d3;
  // border-bottom: 1px solid #d3d3d3;

  .title-div {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    color: #333333;
  }

  .sub-head {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #333333;
  }

  .edit-div {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #285be7;
    cursor: pointer;
  }

  label {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    /* line-height: 19px; */
    /* text-align: center; */
    color: #333333;
  }
}

.first-div {
  .company-title {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #333333;
  }

  .country-title {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #333333;
  }

  .role-div {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    color: #a9a5a5;
  }

  .btn-buyer {
    background: #182269;
    border-radius: 8px;
    border: none;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #ffffff;
    padding: 10px 30px;
  }
}
