.phone-field-div {
  .react-tel-input {
    margin-left: 0px !important;

    .form-control {
      width: 100%;
    }
  }

}
