.sub-head {
  font-size: 20px;
  font-weight: 700;
}

.card-count-text {
  font-size: 16px;
  font-weight: 700;
}

.card-count {
  font-size: 30px;
  color: #2060e7;
}
