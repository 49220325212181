.response-span {
    color: green;
    font-size: 18px;
    font-weight: 600;
}

.response-error {
    color: red;
}

.custom-mapping{
    // width:a 35rem;
}