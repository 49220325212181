.fb-btn {
  background: #4267b2;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  color: #ffffff;
}

.btn-icon {
  position: absolute;
  top: 9px;
  left: 10px;
}

.g-icon {
  background: black;
}

.g-btn {
  background: #ffffff;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  color: #62646a;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 4px;
}

.input-custom {
  font-weight: 500;
  font-size: 14px;
  color: #999999;
}

.sign-in-btn {
  background: #3ec28f;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 4px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  /* line-height: 16px; */
  text-align: center;
  color: #ffffff;
}

.remember-forgot {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  align-items: baseline;
  color: #62646a;
}

.not-register {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #62646a;
}

.join-us {
  color: #3ec28f;
  cursor: pointer;
}

.modal-header-custom {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  letter-spacing: 0.05em;
  color: #000000;
}

.cursor-pointer{
  cursor: pointer;
}

.center-no-data { 
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}