.permission-div {
  border-top: 1px solid #d3d3d3;
}

.permission-check-box-grid {
  grid-template-columns: 1fr 1fr 1fr;
  display: grid;
}

@media only screen and (max-width: 900px) {
  .platform-company-page {
    .row-custom-platform-company {
      flex-direction: column;
      align-items: center;
    }

    .second-div {
      border: none;
    }
  }
}
