.err-msg {
    color: red;
    font-size: 14px;
    max-width: 19rem;
}

.help-text-custom {

    font-size: 12px;
    color: #999999;

}