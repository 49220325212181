.sc-bdvvtL {
  text-align: center;
  padding: 65px 20px !important;
  svg {
    display: none;
  }

  .ebdQkY {
    display: block;
  }

  .kCudqJ span {
    text-decoration: none;
  }
}

.head-div {
  font-size: 16px;
  font-weight: 600;
}

.sub-head-div {
  font-size: 15px;
  font-weight: 500;
}

.help-text-div {
  color: #2596be;
  cursor: pointer;
  font-size: 13px;
}

.status-approve {
  color: green;
}

.status-other {
  color: #898989;
}

uat-list {
  .fa-times-circle:before {
    content: "\f057";
    font-size: 20px;
    color: #e32525;
  }
}

.fa-times-circle:before {
  content: "\f057";
  font-size: 20px;
  color: #e32525;
}