.centered {
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;

  .fa-circle-check:before {
    content: "\f058";
    color: green;
    font-size: 35px;
  }

  .small-text {
    font-size: 13px;
    text-align: center;
  }

  .text-head {
    font-size: 20px;
    font-weight: 700;
  }

  .client-secret-text {
    font-size: 15px;
    font-weight: 600;
    width: 5rem;
  }

  .copy-text {
    background: #d5daed;
    padding: 7px;
    border-radius: 5px;
    width: 315px;
    font-size: 15px;
    font-weight: 500;
  }

  .copy-div {
    align-items: center;
  }

  .back-text {
    color: #095cf0;
    font-size: 12px;
    cursor: pointer;
  }

  .help-text {
    font-size: 12px;
    color: red;
  }
}
