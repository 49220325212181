.react-responsive-modal-root {
  z-index: 99999;
}

.react-responsive-modal-modal {
  border-radius: 7px;
}

.action-icon {
  .fa-ban:before {
    content: "\f05e";
    font-size: 20px;
    color: #788782;
  }

  .fa.fa-trash-o:before {
    content: "\f2ed";
    font-size: 20px;
    color: #788782;
  }

  .fa-check-circle:before {
    content: "\f058";
    font-size: 20px;
    color: green;
  }

  .fa-pencil:before {
    content: "\f303";
    font-size: 20px;
    color: #788782;
  }
}