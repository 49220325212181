.w-50 {
    width: 50%;
}

.form-div {
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 30px;
}

.f-22 {
    font-size: 22px;
}

.help-text-custom {
    font-size: 14px;
}

.left-img {
    height: 100%;
}
.common-color {
    background: linear-gradient(
        90deg,
        rgba(19, 16, 51, 1) 37%,
        rgba(19, 16, 51, 1) 98%,
        rgba(19, 16, 51, 1) 100%
    );
    // background: linear-gradient(
    //     90deg,
    //     rgba(19, 16, 51, 1) 0%,
    //     rgba(43, 27, 94, 1) 98%,
    //     rgba(19, 16, 51, 1) 100%
    // );
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
}

// .grecaptcha-badge {
//   visibility: visible;
// }

.main-div-sign-in {
    background: #ffffff;
    height: 100vh;
    color: white;

    .right-div-header {
        color: #333333;
    }

    .react-tel-input .form-control {
        background: transparent;
        color: white;
        width: 100%;
        padding: 13.5px 16px 15.5px 58px;
        border: 1px solid #476fdb;
        // border: 1px solid #476fdb;
    }

    .phone-error-msg {
        color: #d32f2f;
        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
        font-weight: 400;
        font-size: 0.75rem;
        line-height: 1.66;
        letter-spacing: 0.03333em;
        text-align: left;
        margin-top: 3px;
        margin-left: 14px;
        // border: 1px solid #476fdb;
        margin-bottom: 0;
    }

    .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root:hover
        .MuiOutlinedInput-notchedOutline {
        border-color: #182269;
    }

    .css-1sumxir-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
        // color: #182269;
    }

    fieldset {
        border: 1px solid #d3d3d3;
    }

    .react-tel-input .special-label {
        background: #15226d;
    }

    .react-tel-input .country-list {
        background-color: #15226d;
    }

    .react-tel-input .country-list .country.highlight {
        background-color: #efefef;
        color: black;
    }

    .react-tel-input .country-list .country:hover {
        background-color: #f1f1f1;
        color: black;
    }

    .css-1nact9s-MuiButtonBase-root-MuiButton-root:hover {
        background-color: #182269;
    }
}

.logo-1 {
    position: absolute;
    top: 43px;
    left: 120px;
    width: 75px;
}

.logo-2 {
    position: absolute;
    top: 30px;
    left: 50px;
    width: 55px;
}

.first-div-alignment-center {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    font-size: 4rem;
    width: 23rem;
}

.first-div-alignment-bottom {
    font-size: 0.875rem;
    position: absolute;
    left: 50%;
    bottom: 0;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.MuiInputLabel-outlined {
    // opacity: 0.8;
}

.link-div-older-version {
    position: absolute;
    right: 7px;
    top: 7px;
}
