.upload-text {
  color: #1976d2;
  font-size: 12px;
}

.verify-btn-profile {
  position: absolute;
  top: 30px;
  right: 8px;
}

@media only screen and (max-width: 740px) {
  /* styles for mobile browsers smaller than 480px; (iPhone) */

  .profile-edit-page {
    .row-custom-profile {
      flex-direction: column;
      align-items: center;
    }

    .second-div {
      border: none;
    }
  }
}
