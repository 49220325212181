.react-tel-input .form-control:hover {
    border-color: #476fdb;
  }

  .react-tel-input .form-control {
    background: transparent;
    // color: white;
    width: 100%;
    padding: 13.5px 16px 15.5px 58px;
    border: 1px solid #d3d3d3;
    // border: 1px solid #476fdb;
  }

  .react-tel-input .special-label {
    // background: #15226d;
    color: rgba(0, 0, 0, 0.6)
  }

  .react-tel-input .country-list {
    // background-color: #15226d;
  }

  .react-tel-input .country-list .country.highlight {
    background-color: #efefef;
    color: black;
  }

  .react-tel-input .country-list .country:hover {
    background-color: #f1f1f1;
    color: black;
  }