.css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
  // right: 12px !important;
  // left: revert !important;
}

// .custom-menu-div {
//   position: absolute;
//   background: white;
//   color: black;
//   border-radius: 5px;
// //   box-shadow: 0px 2px 1px -1px rgb(0, 0, 0, 0.20),
// //     0px 1px 1px 0px rgb(0, 0, 0, 0.14), 0px 1px 3px 0px rgb(0, 0, 0, 0.12);
//   padding: 5px 3px;
//   right: 10px;
//   top: 54px;
// }

.active-menu {
  color: white;
  background-color: #1976d2;

  .active-icon {
    color: white
  }
}

.active-menu:hover {
  color: black;

  .active-icon {
    color: black
  }
}