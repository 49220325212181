.w-50 {
    width: 50%;
}

.form-div {
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 30px;
}

.f-22 {
    font-size: 22px;
}

.help-text-custom {
    font-size: 14px;
}

.main-div {
    background: #ffffff;
    color: white;
    // height: 100vh;
    // overflow: auto;

    .right-div-header {
        color: #333333;
    }

    .left-img {
        height: 100%;
    }

    .form-div {
        // height: 100vh;
        // overflow: auto;
    }

    .react-tel-input .form-control:hover {
        border-color: #476fdb;
    }

    .react-tel-input .form-control {
        background: transparent;
        // color: white;
        width: 100%;
        padding: 13.5px 16px 15.5px 58px;
        border: 1px solid #d3d3d3;
        // border: 1px solid #476fdb;
    }

    .phone-error-msg {
        color: #d32f2f;
        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
        font-weight: 400;
        font-size: 0.75rem;
        line-height: 1.66;
        letter-spacing: 0.03333em;
        text-align: left;
        margin-top: 3px;
        margin-left: 14px;
        // border: 1px solid #476fdb;
        margin-bottom: 0;
    }

    .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root:hover
        .MuiOutlinedInput-notchedOutline {
        border-color: #182269;
    }

    fieldset {
        border: 1px solid #d3d3d3;
    }

    .react-tel-input .special-label {
        // background: #15226d;
        color: rgba(0, 0, 0, 0.6);
    }

    .react-tel-input .country-list {
        // background-color: #15226d;
        color: black;
    }

    .react-tel-input .country-list .country.highlight {
        background-color: #efefef;
        color: black;
    }

    .react-tel-input .country-list .country:hover {
        background-color: #f1f1f1;
        color: black;
    }

    .css-1akddpt-MuiButtonBase-root-MuiButton-root:hover {
        background-color: #182269;
    }
}

.logo-1 {
    position: absolute;
    top: 43px;
    left: 120px;
    width: 75px;
}

.logo-2 {
    position: absolute;
    top: 30px;
    left: 50px;
    width: 55px;
}

.first-div-alignment-center {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    font-size: 4rem;
    width: 23rem;
}

.first-div-alignment-bottom {
    font-size: 0.875rem;
    position: absolute;
    left: 50%;
    bottom: 0;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.after-register {
    display: flex;
    align-items: center;
    padding: 6%;
}

.right-img-background {
    width: 100%;
    height: 100%;
    background: url("https://sso.proxtera.app/assets/images/login-bg.png")
        no-repeat center;
    background-size: cover;
}

.verify-btn {
    position: absolute;
    top: 12px;
    right: 8px;
}
